const menuData = [
  {
    title: "Dashboard",
    route: "Dashboard",
    icon: "HomeIcon",
    visible: true,
  },
  {
    title: "eCommerce",
    icon: "ShoppingCartIcon",
    visible: true,
    children: [
      {
        title: "Products",
        icon: "CircleIcon",
        visible: true,
        children: [
          {
            title: "Liste de produits",
            route: "product",
          },
          {
            title: "Ajouter un produit",
            route: "Add Product Simple",
          },
        ],
      },
      {
        title: "Catégories",
        icon: "CircleIcon",
        visible: true,
        children: [
          {
            title: "Liste des catégories",
            route: "Categories",
          },
          {
            title: "Ajouter une catégorie",
            route: "Add Category",
          },
        ],
      },
    ],
  },
];

const visibleMenuData = menuData.filter((item) => item.visible === true);

export default visibleMenuData;
